import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAPI } from "../../axios/utils";
import {
  GENERAL_INFORMATION_API,
  GLOBAL_SETTINGS_API,
  SPONSOR_INFO_API,
  TEAM_MEMBERS_DETAIL_API,
  TEAM_MEMBERS_LIST_API,
} from "../../utils/constants";

export const getGlobalSettings = createAsyncThunk("settings/getGlobalSettings", async () => {
  return getAPI(GLOBAL_SETTINGS_API);
});

export const getGeneralInformation = createAsyncThunk("settings/getGeneralInformation", async () => {
  return getAPI(GENERAL_INFORMATION_API);
});

export const getTeamMembersListAPI = createAsyncThunk("settings/getTeamMembersListAPI", async () => {
  return getAPI(TEAM_MEMBERS_LIST_API);
});

export const getTeamMembersDetailAPI = createAsyncThunk("settings/getTeamMembersDetailAPI", async (id: number) => {
  return getAPI(`${TEAM_MEMBERS_DETAIL_API}${id}`);
});

export const getSponsorDetailAPI = createAsyncThunk("settings/getSponsorDetailAPI", async (refCode: string) => {
  return getAPI(`${SPONSOR_INFO_API}${refCode}`);
});
