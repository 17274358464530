import React from "react";
import { useSearchParams } from "react-router-dom";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import TicketBillingInfo from "./TicketBillingInfo";
import TicketHome from "./TicketHome";
import TicketPaymentConfirmation from "./TicketPaymentConfirmation";
import TicketPurchase from "./TicketPurchase";
import TicketQuestionnaire from "./TicketQuestionnaire";
import TicketStripe from "./TicketStripe";

interface FormStepInterface {
  id: number;
  component: React.ReactElement;
}

export default function Ticket() {
  const handleRouteClick = useHandleRouteClick();
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleNextStep = () => {
    setCurrentStep((prev) => {
      const newStep = prev + 1;
      setSearchParams((prev) => {
        const prevParams: Record<string, any> = {};
        prev.forEach((value, key) => {
          prevParams[key] = value;
        });
        return { ...prevParams, step: String(newStep) };
      });
      return newStep;
    });
  };

  const handlePrevStep = () => {
    setCurrentStep((prev) => {
      const newStep = prev - 1;
      setSearchParams((prev) => {
        const prevParams: Record<string, any> = {};
        prev.forEach((value, key) => {
          prevParams[key] = value;
        });
        return { ...prevParams, step: String(newStep) };
      });
      return newStep;
    });
  };

  const formSteps: FormStepInterface[] = [
    {
      id: 1,
      component: <TicketHome handleNextStep={handleNextStep} />,
    },
    {
      id: 2,
      component: <TicketPurchase handleNextStep={handleNextStep} />,
    },
    {
      id: 3,
      component: <TicketQuestionnaire handleNextStep={handleNextStep} />,
    },
    {
      id: 4,
      component: <TicketBillingInfo handleNextStep={handleNextStep} />,
    },
    {
      id: 5,
      component: <TicketStripe handleNextStep={handleNextStep} />,
    },
    {
      id: 6,
      component: <TicketPaymentConfirmation handleNextStep={() => handleRouteClick(routes.startPage.path)} />,
    },
  ];

  const renderFormStep = () => {
    return formSteps.find((s) => s.id === currentStep)?.component;
  };

  React.useEffect(() => {
    if (searchParams.get("step")) {
      setCurrentStep(parseInt(searchParams.get("step") || "1"));
      setSearchParams((prev) => {
        const prevParams: Record<string, any> = {};
        prev.forEach((value, key) => {
          prevParams[key] = value;
        });
        return { ...prevParams, step: String(searchParams.get("step") || "1") };
      });
    } else {
      setCurrentStep(1);
      setSearchParams((prev) => {
        const prevParams: Record<string, any> = {};
        prev.forEach((value, key) => {
          prevParams[key] = value;
        });
        return { ...prevParams, step: "1" };
      });
    }
  }, []);

  return (
    <div>
      <div>{renderFormStep()}</div>
    </div>
  );
}
