import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import authSlice from "./auth/authSlice";
import paymentsSlice from "./payments/paymentsSlice";
import portfolioSlice from "./portfolio/portfolioSlice";
import questionnaireSlice from "./questionnaire/questionnaireSlice";
import settingsSlice from "./settings/settingsSlice";
import supportSlice from "./support/supportSlice";
import ticketsSlice from "./tickets/ticketsSlice";

const reducers = combineReducers({
  auth: authSlice.reducer,
  support: supportSlice.reducer,
  questionnaire: questionnaireSlice.reducer,
  settings: settingsSlice.reducer,
  portfolio: portfolioSlice.reducer,
  tickets: ticketsSlice.reducer,
  payments: paymentsSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["questionnaire", "settings"],
};

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "auth/getMyDetail/rejected") {
    return reducers(undefined, action);
  }
  return reducers(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (
    getDefaultMiddleware // To remove response headers should not be non-serializable error while returning promise in action creators.
  ) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
export const persistor = persistStore(store);
