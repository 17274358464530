export const BASE_PROTOCOL: string = process.env.REACT_APP_BASE_PROTOCOL || "http";
export const BASE_HOST: string = process.env.REACT_APP_BASE_HOST || "localhost:8000";
export const STRIPE_PUBLISHABLE_KEY: string = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "pk_live_51N8qO6AiuKTqU8iDEDE1uoOV6fLj54JzoEFkwavQdoqmqUL5oiJ24IrWnwtvFVBHD2AYkwJHdxWc5UlELi6f63R500lzVPw6QB"

export const BACKEND_BASE_URL: string = `${BASE_PROTOCOL}://${BASE_HOST}`;

export const AUTH_LOGIN_API = `${BACKEND_BASE_URL}/api/auth/jwt/create/`;
export const AUTH_TOKEN_REFRESH_API = `${BACKEND_BASE_URL}/api/auth/jwt/refresh/`;
export const AUTH_REGISTER_API = `${BACKEND_BASE_URL}/api/auth/users/`;
export const AUTH_MY_DETAIL_API = `${BACKEND_BASE_URL}/api/auth/users/me/`;
export const AUTH_FORGOT_PASSWORD_API = `${BACKEND_BASE_URL}/api/auth/users/reset_password/`;
export const AUTH_FORGOT_PASSWORD_CONFIRM_API = `${BACKEND_BASE_URL}/api/auth/users/reset_password_confirm/`;
export const AUTH_CHANGE_PASSWORD_API = `${BACKEND_BASE_URL}/api/auth/users/set_password/`;
export const AUTH_LOGOUT_API = `${BACKEND_BASE_URL}/api/auth/logout/`;
export const AUTH_GET_NOMINATORS_API = `${BACKEND_BASE_URL}/api/auth/nominators/`;
export const AUTH_CONSENT_AGREEMENT_API = `${BACKEND_BASE_URL}/api/auth/consent-agreement/`;

export const SUPPORT_CONTACT_US_API = `${BACKEND_BASE_URL}/api/support/contactus/`;

export const GLOBAL_SETTINGS_API = `${BACKEND_BASE_URL}/api/settings/`;
export const GENERAL_INFORMATION_API = `${BACKEND_BASE_URL}/api/settings/general-information/`;

export const QUESTIONNAIRE_GET_QUESTIONS_API = `${BACKEND_BASE_URL}/api/questionnaire/questions/`;
export const QUESTIONNAIRE_GET_RESPONSES_API = `${BACKEND_BASE_URL}/api/questionnaire/responses/`;
export const QUESTIONNAIRE_RESPONSE_SUBMIT_API = `${BACKEND_BASE_URL}/api/questionnaire/submit/`;
export const QUESTIONNAIRE_RESPONSE_UPDATE_API = `${BACKEND_BASE_URL}/api/questionnaire/update/`;

export const GET_PLATFORM_PORTFOLIO_API = `${BACKEND_BASE_URL}/api/portfolio/platforms/`;
export const GET_PRESSES_PORTFOLIO_API = `${BACKEND_BASE_URL}/api/portfolio/presses/`;
export const GET_PRESS_TEXT_PORTFOLIO_API = `${BACKEND_BASE_URL}/api/portfolio/press-texts/`;
export const GET_MAGAZINES_API = `${BACKEND_BASE_URL}/api/portfolio/magazines/`;

export const PAYMENT_BILLING_INFO_SAVE_API = `${BACKEND_BASE_URL}/api/payments/billing-info/`;
export const PAYMENT_CREATE_STRIPE_INTENT_API = `${BACKEND_BASE_URL}/api/payments/intent/`;
export const PAYMENT_CAPTURE_STRIPE_PAYMENT_API = `${BACKEND_BASE_URL}/api/payments/capture/`;
export const PAYMENT_HISTORY_API = `${BACKEND_BASE_URL}/api/payments/purchase-history/`;

export const TICKET_SALE_SETTINGS_API = `${BACKEND_BASE_URL}/api/ticket/ticket-sale-settings/`;
export const TICKET_COUPON_CODE_VALIDATE_API = `${BACKEND_BASE_URL}/api/ticket/validate-coupon/`;

export const TEAM_MEMBERS_DETAIL_API = `${BACKEND_BASE_URL}/api/team/members/`;
export const TEAM_MEMBERS_LIST_API = `${BACKEND_BASE_URL}/api/team/members/`;

export const CONFIRM_GUEST_API = `${BACKEND_BASE_URL}/api/sponsors/confirm/guest/`;
export const INVITE_GUEST_API = `${BACKEND_BASE_URL}/api/sponsors/invite/guest/`;
export const SPONSOR_INFO_API = `${BACKEND_BASE_URL}/api/sponsors/sponsor/`;

export const DREAM_HUB = "DREAM_HUB";
export const XLAB = "XLAB";

export const PLATFORM_TYPE = {
  DREAM_HUB: DREAM_HUB,
  XLAB: XLAB,
};

export const FOOD_TYPE = {
  VEGETARIAN: "VEGETARIAN",
  NON_VEGETARIAN: "NON_VEGETARIAN",
};

export const QUESTION_TYPE = {
  MULTI_SELECT: "MULTI_SELECT",
  MULTI_SELECT_ORDERED: "MULTI_SELECT_ORDERED",
  SINGLE_SELECT: "SINGLE_SELECT",
  TEXT: "TEXT",
  NUMBER: "NUMBER",
  CURRENCY: "CURRENCY",
  FILES_UPLOAD: "FILES_UPLOAD",
  COLORS: "COLORS",
} as const;

export type QUESTION_TYPE_PROPS = (typeof QUESTION_TYPE)[keyof typeof QUESTION_TYPE];

export const enum SUBMIT_STATUS {
  INITIAL = "initial",
  SUCCESS = "success",
  FAILED = "failed",
}
