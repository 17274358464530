import React from "react";
import { useLocation } from "react-router-dom";
import { routes } from "../routes";
import { useHandleRouteClick } from "../routes/hooks";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getGeneralInformation, getGlobalSettings } from "../store/settings/settingsThunk";
import { getTicketSaleSettings } from "../store/tickets/ticketsThunk";
import styles from "../styles/BaseLayout.module.scss";
import Footer from "./Footer";
import Navbar from "./Navbar";
import UiLoading from "./UiLoading";

export interface BaseLayoutProps {
  children?: React.ReactNode;
}

export default function BaseLayout({ children }: BaseLayoutProps) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const handleRouteClick = useHandleRouteClick();
  const { isLoading: isAuthLoading } = useAppSelector((state) => state.auth);
  const { isLoading: isPortfolioLoading } = useAppSelector((state) => state.portfolio);
  const { isLoading: isQuestionnaireLoading } = useAppSelector((state) => state.questionnaire);
  const { isLoading: isSettingsLoading } = useAppSelector((state) => state.settings);
  const { isLoading: isSupportLoading } = useAppSelector((state) => state.support);
  const { isLoading: isPaymentsLoading } = useAppSelector((state) => state.payments);
  const { isLoading: isTicketsLoading } = useAppSelector((state) => state.tickets);

  React.useEffect(() => {
    dispatch(getGlobalSettings());
    dispatch(getTicketSaleSettings());
    dispatch(getGeneralInformation());
  }, []);

  // console.log(location.pathname, routes.press.path);
  // console.log(location.pathname === routes.startPage.path || location.pathname === routes.login.path || location.pathname === routes.services.path);

  return (
    <div className={`${styles["base-layout"]} mx-4`}>
      {(isAuthLoading ||
        isPortfolioLoading ||
        isQuestionnaireLoading ||
        isSettingsLoading ||
        isSupportLoading ||
        isPaymentsLoading ||
        isTicketsLoading) && <UiLoading height="100vh" />}

      {location.pathname.includes(routes.login.path) || location.pathname.includes(routes.register.path) ? (
        <>
          <Navbar />
          {children}
          <Footer />
        </>
      ) : (
        <>
          <div>
            <Navbar />
            {!(
              location.pathname === routes.startPage.path ||
              location.pathname.includes(routes.login.path) ||
              location.pathname.includes(routes.register.path) ||
              location.pathname.includes(routes.home.path) ||
              location.pathname.includes(routes.services.path) ||
              location.pathname.includes(routes.dreamHub.path) ||
              location.pathname.includes(routes.xlab.path)
            ) && (
              <div className="mt-4 mb-4">
                <h4 className="font-didot-bold" onClick={() => handleRouteClick(routes.home.path)} style={{ cursor: "pointer", textAlign: "left" }}>
                  Home
                </h4>
              </div>
            )}
            <div>{children}</div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}
