import { useSearchParams } from "react-router-dom";
import TicketPaymentDetails from "./TicketPaymentDetails";
import TicketPaymentProcess from "./TicketPaymentProcess";

interface TicketStripeProps {
  handleNextStep: () => void;
}

export default function TicketStripe({ handleNextStep }: TicketStripeProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const renderStripe = () => {
    if (searchParams.get("client_secret") && searchParams.get("ticket_count")) {
      return <TicketPaymentProcess handleNextStep={handleNextStep} />;
    } else {
      return <TicketPaymentDetails handleNextStep={handleNextStep} />;
    }
  };

  return renderStripe();
}
