import React from "react";
import { toast } from "react-toastify";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { getMyDetail, saveConsentAgreement, updateMyDetail } from "../../../store/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import styles from "../../../styles/TicketQuestionnaire.module.scss";
import { FOOD_TYPE } from "../../../utils/constants";

interface TicketQuestionnaireProps {
  handleNextStep: () => void;
}

interface QuestionInterface {
  id: number;
  component: React.ReactElement;
}

interface ConsentAgreementProps {
  onSubmit: () => void;
}

interface FoodChoiceProps {
  onSubmit: () => void;
}

const ConsentAgreement = ({ onSubmit }: ConsentAgreementProps) => {
  const dispatch = useAppDispatch();

  return (
    <div className={`text-center ${styles["questionnaire-sub-container"]}`}>
      <h4 className="font-didot-bold mb-4">HXS Terms and Conditions</h4>
      <div>
        <div className="mb-4 col-md-6 offset-md-3 font-didot">
          <h6 className="m-0 w-100 font-didot-bold">Terms and Conditions of Ticket Purchase:</h6>
          <p>
            By purchasing a ticket to our philosophical art show, you agree to the following terms and conditions. Please read carefully before
            finalizing your purchase:
          </p>

          <h6 className="m-0 w-100 font-didot-bold">Event Nature and Venue:</h6>
          <p>
            This is a theatrical philosophical art show, which explores deep philosophical and artistic themes. While we strive to provide a seamless
            experience, unforeseen circumstances may arise. In the unlikely event that our venue (including but not limited to church spaces) or any
            critical vendor withdraws or is unavailable, we reserve the right to change the venue and/or date of the show. In such cases, you will be
            notified of any changes via the contact information you provide at purchase.
          </p>

          <h6 className="m-0 w-100 font-didot-bold">Non-Refundable Tickets:</h6>
          <p>
            All tickets are non-refundable except in the event of the show's complete cancellation. If the event is canceled, a full refund will be
            provided to the original ticket purchaser.
          </p>

          <h6 className="m-0 w-100 font-didot-bold">Refund Policy:</h6>
          <p>
            Refunds will only be issued if the show is officially canceled. No refunds will be provided if the show is rescheduled to a new venue or
            date, or if a ticket holder is unable to attend the rescheduled event.
          </p>

          <h6 className="m-0 w-100 font-didot-bold">Right to Remove Audience Members:</h6>
          <p>
            We strive to maintain a safe and respectful environment for all attendees. If an audience member causes a disruption, behaves in a manner
            that negatively impacts the show, or their presence becomes problematic, we reserve the right to remove that individual from the event
            without a refund.
          </p>

          <h6 className="m-0 w-100 font-didot-bold">Live Stream Disclaimer:</h6>
          <p>
            If you are purchasing access to a live stream of the show, please note that the quality of the stream may be affected by factors outside
            of our control, such as your internet connection speed, Wi-Fi stability, or other technical issues. We are not responsible for any
            interruptions, delays, or problems in viewing the live stream due to these factors.
          </p>

          <h6 className="m-0 w-100 font-didot-bold">Disclaimer of Liability:</h6>
          <p>
            By attending our show, you acknowledge that you are doing so voluntarily. While the show is designed to be safe, we are not responsible
            for any physical or emotional injuries, illnesses, or harm that may occur during or after the event. This includes any mental or emotional
            impact from the themes explored during the performance.
          </p>

          <h6 className="m-0 w-100 font-didot-bold">No Recording Policy:</h6>
          <p>
            Unauthorized photography, video recording, or audio recording of the event is strictly prohibited. We value the integrity of our
            performance and the experience of our audience. Any violation of this policy may result in removal from the event without refund and
            further legal action.
          </p>

          <h6 className="m-0 w-100 font-didot-bold">Force Majeure:</h6>
          <p>
            In the case of unforeseen events beyond our control, such as natural disasters, pandemics, government restrictions, or other "Acts of
            God," that force us to cancel or reschedule the show, we will make every reasonable effort to notify ticket holders. Refunds may not be
            available for cancellations due to such events.
          </p>

          <h6 className="m-0 w-100 font-didot-bold">Health and Safety:</h6>
          <p>
            You are responsible for your own health and safety while attending the show. If you feel unwell, we ask that you refrain from attending
            for the safety of others. We are not liable for any illness or injury sustained while attending the event.
          </p>

          <h6 className="m-0 w-100 font-didot-bold">Governing Law and Jurisdiction:</h6>
          <p>These terms and conditions are governed by the laws of United States of America.</p>
        </div>
      </div>
      <div>
        <button
          type="button"
          onClick={() => {
            dispatch(saveConsentAgreement()).then((res) => {
              if (res.payload.status === 200) {
                onSubmit();
                dispatch(getMyDetail());
              } else {
                toast.error("Some error");
              }
            });
          }}
          className="font-graebenbach w-auto px-3 my-5 my-md-5"
        >
          I AGREE
        </button>
      </div>
    </div>
  );
};

const FoodChoice = ({ onSubmit }: FoodChoiceProps) => {
  const dispatch = useAppDispatch();

  return (
    <div className={`text-center ${styles["questionnaire-sub-container"]}`}>
      <h4 className="font-didot-bold mb-4">Food</h4>
      <div>
        <div className="mb-4 col-md-4 offset-md-4">
          <h6 className="m-0 w-100 font-didot">
            Upon arrival you will be taken to your reservation and served with delicacies while you sit in silence experiencing the show. There is no
            additional cost to you.
          </h6>
        </div>
        <div className="mb-4 col-md-4 offset-md-4">
          <h6 className="m-0 w-100 font-didot">Please pre-select your dining preference. You can always change your preference at the event.:</h6>
        </div>
      </div>
      <div className="d-flex gap-3 justify-content-center">
        <button
          type="button"
          onClick={() => {
            dispatch(updateMyDetail({ food: FOOD_TYPE.NON_VEGETARIAN })).then((res) => {
              if (res.payload.status === 200) {
                onSubmit();
              } else {
                toast.error("Some error");
              }
            });
          }}
          className="font-graebenbach w-auto px-3 my-5 my-md-5"
        >
          NON-VEGETARIAN
        </button>
        <button
          type="button"
          onClick={() => {
            dispatch(updateMyDetail({ food: FOOD_TYPE.VEGETARIAN })).then((res) => {
              if (res.payload.status === 200) {
                onSubmit();
              } else {
                toast.error("Some error");
              }
            });
          }}
          className="font-graebenbach w-auto px-3 my-5 my-md-5"
        >
          VEGETARIAN
        </button>
      </div>
    </div>
  );
};

export default function TicketQuestionnaire({ handleNextStep: nextStep }: TicketQuestionnaireProps) {
  const { user } = useAppSelector((state) => state.auth);
  const handleRouteClick = useHandleRouteClick();
  const [currentStep, setCurrentStep] = React.useState<number>(1);

  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const questionSteps: QuestionInterface[] = [
    {
      id: 1,
      component: (
        <FoodChoice
          onSubmit={() => {
            handleNextStep();
          }}
        />
      ),
    },
    {
      id: 2,
      component: <ConsentAgreement onSubmit={() => nextStep()} />,
    },
  ];

  React.useEffect(() => {
    if (user && user?.is_agreed_hxs_house_rules) {
      if (!user?.food) {
        // onSubmit();
        // setCurrentStep(questionSteps[questionSteps.length - 1].id);
      }
    }
  }, []);

  return (
    <div>
      <div className="my-3 d-flex justify-content-between">
        <div>
          <a href={routes.home.path}>
            <h4 className="font-didot-bold" style={{ cursor: "pointer" }}>
              Home
            </h4>
          </a>
        </div>
        <div>
          <h4 className="font-didot-bold" onClick={() => handleRouteClick(routes.logout.path)} style={{ cursor: "pointer" }}>
            Log Out
          </h4>
        </div>
      </div>
      <div className={styles["questionnaire"]}>{questionSteps.find((s) => s.id === currentStep)?.component}</div>
    </div>
  );
}
