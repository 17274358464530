interface RescheduleAgreementModalProps {
  open: boolean;
  toggleModal: () => void;
}

export default function RescheduleAgreementModal({ open, toggleModal }: RescheduleAgreementModalProps) {
  return (
    <>
      <div
        className="modal fade m-0 p-0"
        id="reschedule_agreement_modal"
        tabIndex={-1}
        aria-labelledby="reschedule_agreement_modal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              {/* <h5 className="modal-title text-black font-didot-bold" id="exampleModalToggleLabel">
                Live Stream
              </h5> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  toggleModal();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <p className="font-graebenbach">
                Please note that our shows may explore thought-provoking themes, which could lead to adjustments in the date or location. In the event
                of such changes, we will notify you promptly and provide a similar venue and timeframe. Tickets are non-refundable; however, your
                purchase guarantees free entry to the rescheduled show.
              </p>
              <div className="d-flex justify-content-center gap-2">
                <button data-bs-dismiss="modal" type="submit" className="font-graebenbach submit-btn px-4 py-1 my-2 my-md-2">
                  SUBMIT
                </button>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    toggleModal();
                  }}
                  className="font-graebenbach submit-btn px-4 py-1 my-2 my-md-2"
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
