import { Navigate } from "react-router-dom";
import { routes } from "../../routes";
import { useAppSelector } from "../../store/hooks";
import Ticket from "./ticket";

export default function HomePage() {
  const { user, isAuthenticated } = useAppSelector((state) => state.auth);

  if (!isAuthenticated) {
    return <Navigate to={routes.login.path} />;
  }

  return (
    <div>
      <div className={`mt-4 mb-5`}>
        <Ticket />
      </div>
    </div>
  );
}
