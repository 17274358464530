import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getPlatformPortfolio } from "../../store/portfolio/portfolioThunk";
import styles from "../../styles/Portfolio.module.scss";
import PlatformProjectImages from "../portfolio/components/PlatformProjectImages";
import SidePlatformSelector from "../portfolio/components/SidePlatformSelector";

export default function PortfolioProjectPage() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { platforms } = useAppSelector((state) => state.portfolio);

  const allProjects = platforms.map((p) => p.portfolio_projects);
  // console.log(allProjects);
  const currentProject = allProjects.flat().find((p) => p.slug === params?.projectSlug);
  // console.log(params?.projectSlug, currentProject);

  React.useEffect(() => {
    if (platforms?.length <= 0) {
      dispatch(getPlatformPortfolio());
    }
  }, []);

  React.useEffect(() => {
    setSearchParams({ platformName: currentProject?.platform_slug });
  }, [platforms]);

  return (
    <div className="container-fluid pt-4 pb-5 px-0 px-md-auto">
      <div className="row position-relative">
        <div className={`col-0 col-md-3 mb-5 mb-md-auto ${styles["side-selector-position"]}`}>
          <SidePlatformSelector platforms={platforms} />
        </div>
        <div className={"col-12 col-md-12"}>
          <>{currentProject ? <PlatformProjectImages project={currentProject} /> : <h5 className="center font-didot">No Projects Found</h5>}</>
        </div>
      </div>
    </div>
  );
}
