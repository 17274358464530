import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAPI, patchAPI, postAPI } from "../../axios/utils";
import {
  AUTH_CHANGE_PASSWORD_API,
  AUTH_CONSENT_AGREEMENT_API,
  AUTH_FORGOT_PASSWORD_API,
  AUTH_FORGOT_PASSWORD_CONFIRM_API,
  AUTH_GET_NOMINATORS_API,
  AUTH_LOGIN_API,
  AUTH_LOGOUT_API,
  AUTH_MY_DETAIL_API,
  AUTH_REGISTER_API,
} from "../../utils/constants";

export const login = createAsyncThunk("auth/login", async (data: Record<string, any>, thunkAPI) => {
  try {
    return await postAPI(AUTH_LOGIN_API, data);
  } catch (error: any) {
    console.log(error);
    return thunkAPI.rejectWithValue({ error: error.response });
  }
});

export const logout = createAsyncThunk("auth/logout", async (data: Record<string, string>, thunkAPI) => {
  try {
    return await postAPI(AUTH_LOGOUT_API, data);
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.response });
  }
});

export const register = createAsyncThunk("auth/register", async (data: Record<string, any>) => {
  let url = AUTH_REGISTER_API;
  if ("ref_code" in data) {
    url = `${url}?ref_code=${data.ref_code}`;
  }
  return postAPI(url, {
    ...data,
    redirect_url: `${window.location.origin}/verify/email`,
  });
});

export const getMyDetail = createAsyncThunk("auth/getMyDetail", async () => {
  return getAPI(AUTH_MY_DETAIL_API);
});

export const updateMyDetail = createAsyncThunk("auth/updateMyDetail", async (data: Record<string, any>) => {
  return patchAPI(AUTH_MY_DETAIL_API, data);
});

export const forgotPassword = createAsyncThunk("auth/forgotPassword", async (data: Record<string, any>) => {
  const response = await postAPI(AUTH_FORGOT_PASSWORD_API, {
    ...data,
    redirect_url: `${window.location.origin}/set-password`,
  });
  return response;
});

export const forgotPasswordConfirm = createAsyncThunk("auth/forgotPasswordConfirm", async (data: Record<string, any>, thunkAPI) => {
  return postAPI(AUTH_FORGOT_PASSWORD_CONFIRM_API, data);
});

export const changePassword = createAsyncThunk("auth/changePassword", async (data: Record<string, any>) => {
  return postAPI(AUTH_CHANGE_PASSWORD_API, data);
});

export const getNominators = createAsyncThunk("auth/getNominators", async () => {
  return getAPI(AUTH_GET_NOMINATORS_API);
});

export const saveConsentAgreement = createAsyncThunk("auth/saveConsentAgreement", async () => {
  return postAPI(AUTH_CONSENT_AGREEMENT_API);
});
