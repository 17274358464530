import { createSlice } from "@reduxjs/toolkit";

import { confirmGuestTicket, getTicketSaleSettings, inviteGuest, validateTicketCouponCode } from "./ticketsThunk";

export interface TicketsInitialStateProps {
  isLoading: boolean;
  settings: Record<string, any>;
}

const TicketsInitialState: TicketsInitialStateProps = {
  isLoading: false,
  settings: {},
};

const ticketsSlice = createSlice({
  name: "tickets",
  initialState: TicketsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    // For getting the ticket sale settings
    builder
      .addCase(getTicketSaleSettings.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTicketSaleSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.settings = action.payload.data;
      })
      .addCase(getTicketSaleSettings.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For validating ticket coupon code
    builder
      .addCase(validateTicketCouponCode.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(validateTicketCouponCode.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(validateTicketCouponCode.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For confirming guest ticket
    builder
      .addCase(confirmGuestTicket.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(confirmGuestTicket.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(confirmGuestTicket.rejected, (state, action) => {
        state.isLoading = false;
      });

    // For inviting guests
    builder
      .addCase(inviteGuest.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(inviteGuest.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(inviteGuest.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const ticketsActions = ticketsSlice.actions;

export default ticketsSlice;
